//
// user.scss
// Use this to write your custom SCSS
//

.logo-landing {
  width: 6rem;
}
.bg-pic {
  height: 100vh;
}
@media (max-width: 500px) {
  .logo-landing {
    width: 4rem;
  }
  .bg-pic {
    height: 70vh;
  }
}

.bg-recycle {
  background-image: url("../images/brand/pollution.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
}

.mask-dark {
  background-color: rgba(0, 0, 0, 0.256);
  width: 100%;
  height: 100%;
}
